import type { VisitorLogger } from '@wix/yoshi-flow-editor';
import type { AppStore } from '@infrastructure/redux/store.types';
import type {
  myWalletManageButtonClickedParams,
  myWalletRemovePaymentMethodButtonClickedParams,
  myWalletAddPaymentMethodButtonRenderedParams,
  myWalletAddPaymentMethodButtonClickedParams,
  myWalletNewPaymentMethodWasAddedParams,
  myWalletPaymentMethodSetPrimaryClickedParams,
} from '@wix/bi-logger-payments-data/v2/types';
import {
  myWalletManageButtonClicked,
  myWalletRemovePaymentMethodButtonClicked,
  myWalletAddPaymentMethodButtonRendered,
  myWalletAddPaymentMethodButtonClicked,
  myWalletNewPaymentMethodWasAdded,
  myWalletPaymentMethodSetPrimaryClicked,
} from '@wix/bi-logger-payments-data/v2';
import { savedPaymentMethodsSelectors } from '@infrastructure/redux/savedPaymentMethod';
import { mapToBiPaymentMethodData } from '../BiLogger.helpers';

export const paymentMethodsLogger = (
  logger: VisitorLogger,
  store: AppStore,
) => {
  const reportAddButtonRendered = (
    params: myWalletAddPaymentMethodButtonRenderedParams = {},
  ) => {
    logger.report(myWalletAddPaymentMethodButtonRendered(params));
  };

  const reportAddButtonClicked = (
    params: myWalletAddPaymentMethodButtonClickedParams = {},
  ) => {
    logger.report(myWalletAddPaymentMethodButtonClicked(params));
  };

  const reportPaymentMethodAdded = (
    params: myWalletNewPaymentMethodWasAddedParams,
  ) => {
    logger.report(myWalletNewPaymentMethodWasAdded(params));
  };

  const reportManageButtonClicked = (
    params: myWalletManageButtonClickedParams,
  ) => {
    const { savedPaymentMethodId = '' } = params;
    const paymentMethod = savedPaymentMethodsSelectors.getMethodById(
      savedPaymentMethodId,
    )(store.getState());

    logger.report(
      myWalletManageButtonClicked({
        ...params,
        ...mapToBiPaymentMethodData(paymentMethod),
      }),
    );
  };

  const reportRemoveButtonClicked = (
    params: myWalletRemovePaymentMethodButtonClickedParams,
  ) => {
    const { savedPaymentMethodId = '' } = params;
    const paymentMethod = savedPaymentMethodsSelectors.getMethodById(
      savedPaymentMethodId,
    )(store.getState());

    logger.report(
      myWalletRemovePaymentMethodButtonClicked({
        ...params,
        ...mapToBiPaymentMethodData(paymentMethod),
      }),
    );
  };

  const reportSetPrimaryClicked = (
    params: myWalletPaymentMethodSetPrimaryClickedParams,
  ) => {
    const { savedPaymentMethodId = '' } = params;
    const paymentMethod = savedPaymentMethodsSelectors.getMethodById(
      savedPaymentMethodId,
    )(store.getState());

    logger.report(
      myWalletPaymentMethodSetPrimaryClicked({
        ...params,
        paymentMethod: paymentMethod?.paymentMethod.typeId,
      }),
    );
  };

  return {
    reportAddButtonRendered,
    reportAddButtonClicked,
    reportPaymentMethodAdded,
    reportManageButtonClicked,
    reportRemoveButtonClicked,
    reportSetPrimaryClicked,
  };
};
