import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { getAppIdentityParams } from '@utils/app';
import type { AppStore } from '@infrastructure/redux/store.types';
import { createBiLogger } from '@services/BiLogger';

export const initializeBi = (
  controllerParams: ControllerParams,
  store: AppStore,
) => {
  const { controllerConfig, flowAPI } = controllerParams;

  if (flowAPI.bi) {
    const biLogger = createBiLogger(flowAPI.bi, store);
    const appParams = getAppIdentityParams(controllerConfig);

    biLogger.updateDefaults({
      appSessionId: appParams.appSessionId,
    });
    biLogger.reportPageLoadStarted();

    return biLogger;
  }
};
