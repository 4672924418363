import { combineReducers } from '@reduxjs/toolkit';
import { appReducer } from './app';
import { modalsReducer } from './modal';
import { paymentMethodsReducer } from './paymentMethod';
import { savedPaymentMethodsReducer } from './savedPaymentMethod';
import { toastsReducer } from './toast';

export const rootReducer = combineReducers({
  app: appReducer,
  modals: modalsReducer,
  paymentMethods: paymentMethodsReducer,
  savedPaymentMethods: savedPaymentMethodsReducer,
  toasts: toastsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
