import type { SavedPaymentMethodWithMeta, SavedPaymentMethod } from '@appTypes';
import { concatWithMetaData } from '@utils/paymentMethodMapper';
import { BaseHttpService } from '../BaseHttpService';
import {
  fetchSavedPaymentMethods,
  markSavedPaymentMethodAsPrimary,
  removeSavedPaymentMethod,
} from './http';

export class SavedPaymentMethodService extends BaseHttpService {
  async getAll(): Promise<SavedPaymentMethodWithMeta[] | undefined> {
    const { savedPaymentMethods } = await this.withErrorHandler(() =>
      fetchSavedPaymentMethods(this.httpClient, {
        siteMemberId: 'me',
      }),
    );

    return concatWithMetaData(
      savedPaymentMethods as SavedPaymentMethod[],
      this.experiments,
    );
  }

  async markAsPrimary(savedPaymentMethodId: string): Promise<void> {
    await this.withErrorHandler(() =>
      markSavedPaymentMethodAsPrimary(this.httpClient, {
        savedPaymentMethodId,
      }),
    );
  }

  async remove(savedPaymentMethodId: string): Promise<void> {
    await this.withErrorHandler(() =>
      removeSavedPaymentMethod(this.httpClient, {
        savedPaymentMethodId,
      }),
    );
  }
}
