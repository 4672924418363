import type { ControllerParams, IWixAPI } from '@wix/yoshi-flow-editor';
import type { PaymentMethod } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import type { AppIdentityParams } from '@components/AppContext';
import {
  SITE_CURRENCY_DEFAULT,
  supportedPaymentMethodType,
} from '../app.config';

export const getAppIdentityParams = (
  controllerConfig: ControllerParams['controllerConfig'],
): AppIdentityParams => {
  const {
    appParams,
    platformAPIs: { bi },
  } = controllerConfig;
  const { appDefinitionId, instanceId, instance } = appParams;

  return {
    appDefinitionId,
    appInstance: instance,
    appInstanceId: instanceId,
    metaSiteId: bi?.metaSiteId,
    siteOwnerId: bi?.ownerId,
    appSessionId: bi?.viewerSessionId,
    visitorId: bi?.visitorId,
  };
};

export const getSiteCurrency = (wixCodeApi: IWixAPI): string =>
  wixCodeApi.site.currency || SITE_CURRENCY_DEFAULT;

export const canAddNewPaymentMethod = (
  paymentMethods: PaymentMethod[],
): boolean =>
  paymentMethods.some((method) =>
    method.paymentMethod
      ? supportedPaymentMethodType.includes(method.paymentMethod)
      : false,
  );

export const getAccountId = (appParams: AppIdentityParams): string =>
  `${appParams.appDefinitionId}:${appParams.appInstanceId}`;
