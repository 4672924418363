import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { list } from '@wix/ambassador-cashier-pay-v2-payment-method/http';
import type {
  ListPaymentMethodsRequest,
  ListPaymentMethodsResponse,
} from '@wix/ambassador-cashier-pay-v2-payment-method/types';

export const fetchPaymentMethods = async (
  httpClient: IHttpClient,
  payload: ListPaymentMethodsRequest,
): Promise<ListPaymentMethodsResponse> => {
  const { data } = await httpClient.request(list(payload));

  return data;
};
