import { createSlice } from '@reduxjs/toolkit';
import { getIsRecurringSupported } from './actions';

type State = {
  isAlreadyFetchedOnce?: boolean;
  canAddNewPaymentMethod?: boolean;
};

export const initialState: State = {
  isAlreadyFetchedOnce: false,
  canAddNewPaymentMethod: false,
};

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIsRecurringSupported.rejected, (state) => {
      state.isAlreadyFetchedOnce = true;
    });
    builder.addCase(getIsRecurringSupported.fulfilled, (state, action) => {
      state.canAddNewPaymentMethod = action.payload;
      state.isAlreadyFetchedOnce = true;
    });
  },
});

export const { reducer: paymentMethodsReducer } = paymentMethodsSlice;
