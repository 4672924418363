import { configureStore } from '@reduxjs/toolkit';
import { rootReducer, type AppState } from './rootReducer';
import type { ThunkArguments } from './store.types';

export const createStore = (
  thunkArguments: ThunkArguments,
  initialState: Partial<AppState>,
) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: { extraArgument: thunkArguments },
      }),
  });
