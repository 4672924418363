import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  createWidgetPluginExports,
  createMembersAreaWidgetPluginService,
} from '@wix/members-area-widget-plugin-lib/viewer';
import { SettingsEventManager } from '@services/SettingsEventManager';
import { initializeWidget } from './initializeWidget';
import '../polyfills';

const createController: CreateControllerFn = async (controllerParams) => {
  const { controllerConfig, flowAPI } = controllerParams;
  const membersAreaPluginService = createMembersAreaWidgetPluginService();
  const settingsEventManager = new SettingsEventManager(
    controllerConfig.config.publicData.COMPONENT,
  );

  const initialize = async () => {
    try {
      await initializeWidget(controllerParams, settingsEventManager);
    } catch (error) {
      controllerConfig.setProps({ hasInitError: true });

      if (error instanceof Error) {
        flowAPI.errorMonitor.captureException(error);
      }
    }
  };

  return {
    async pageReady() {
      const isRendered = membersAreaPluginService.getIsRendered();

      if (!isRendered) {
        return Promise.resolve();
      }

      await initialize();
    },
    updateConfig(_, { publicData }) {
      settingsEventManager.notify(publicData.COMPONENT);
    },
    exports: () => {
      // Doesn't throw an error in the controller, so it is necessary
      // to manually set the initialization error to display the error screen
      return createWidgetPluginExports(membersAreaPluginService, initialize);
    },
  };
};

export default createController;
