import type { VisitorLogger } from '@wix/yoshi-flow-editor';
import type { myWalletToastMessageShownParams } from '@wix/bi-logger-payments-data/v2/types';
import { myWalletToastMessageShown } from '@wix/bi-logger-payments-data/v2';
import type { AppStore } from '@infrastructure/redux/store.types';
import { getCurrentToast } from '@infrastructure/redux/toast/selectors';

export const notificationsLogger = (logger: VisitorLogger, store: AppStore) => {
  const reportToastShown = (params: myWalletToastMessageShownParams = {}) => {
    const currentToast = getCurrentToast(store.getState());

    logger.report(
      myWalletToastMessageShown({
        ...params,
        toastText: currentToast?.text,
        toastType: currentToast?.meta?.action,
      }),
    );
  };

  return {
    reportToastShown,
  };
};
