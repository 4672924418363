import type { PaymentMethod } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { ChargeIntent } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { BaseHttpService } from '../BaseHttpService';
import { fetchPaymentMethods } from './http';

export class PaymentMethodService extends BaseHttpService {
  async getRecurringPaymentMethods(
    accountId: string,
  ): Promise<PaymentMethod[] | undefined> {
    const { paymentMethods } = await this.withErrorHandler(() =>
      fetchPaymentMethods(this.httpClient, {
        accountId,
        chargeIntent: ChargeIntent.RECURRING,
      }),
    );

    return paymentMethods;
  }
}
