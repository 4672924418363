import type { VisitorLogger } from '@wix/yoshi-flow-editor';
import type { AppStore } from '@infrastructure/redux/store.types';
import { pageLoadLogger } from './loggers/pageLoadLogger';
import { paymentMethodsLogger } from './loggers/paymentMethodsLogger';
import { modalsLogger } from './loggers/modalsLogger';
import { notificationsLogger } from './loggers/notificationsLogger';

export const createBiLogger = (logger: VisitorLogger, store: AppStore) => {
  const pageEvents = pageLoadLogger(logger, store);
  const paymentMethodsEvents = paymentMethodsLogger(logger, store);
  const modalsEvents = modalsLogger(logger, store);
  const notificationsEvents = notificationsLogger(logger, store);

  return {
    ...pageEvents,
    ...paymentMethodsEvents,
    ...modalsEvents,
    ...notificationsEvents,
    updateDefaults: logger?.updateDefaults,
  };
};
