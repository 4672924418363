import type { SitePaymentMethodType } from '@wix/ambassador-payments-site-payment-method-types-v1-site-payment-method-type/types';
import { BaseHttpService } from '../BaseHttpService';
import { fetchListSitePaymentMethodTypes } from './http';

export class PaymentMethodTypesService extends BaseHttpService {
  async getSitePaymentMethodTypes(
    ids: string[],
  ): Promise<SitePaymentMethodType[] | undefined> {
    const { sitePaymentMethodTypes } = await this.withErrorHandler(() =>
      fetchListSitePaymentMethodTypes(this.httpClient, {
        ids,
      }),
    );

    return sitePaymentMethodTypes;
  }
}
