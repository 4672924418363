import type { VisitorLogger } from '@wix/yoshi-flow-editor';
import type { AppStore } from '@infrastructure/redux/store.types';
import type {
  myWalletPageStartedLoadingParams,
  myWalletPageFinishedLoadingParams,
} from '@wix/bi-logger-payments-data/v2/types';
import {
  myWalletPageStartedLoading,
  myWalletPageFinishedLoading,
} from '@wix/bi-logger-payments-data/v2';
import { savedPaymentMethodsSelectors } from '@infrastructure/redux/savedPaymentMethod';
import { getBiPaymentMethodsList } from '../BiLogger.helpers';

export const pageLoadLogger = (logger: VisitorLogger, store: AppStore) => {
  let pageLoadStartTime: number;

  const reportPageLoadStarted = (
    params: myWalletPageStartedLoadingParams = {},
  ) => {
    pageLoadStartTime = Date.now();
    logger.report(myWalletPageStartedLoading(params));
  };

  const reportPageLoaded = (params: myWalletPageFinishedLoadingParams = {}) => {
    const state = store.getState();
    const methods = savedPaymentMethodsSelectors.getAllMethods(state);

    logger.report(
      myWalletPageFinishedLoading({
        ...params,
        duration: pageLoadStartTime && Date.now() - pageLoadStartTime,
        hasSavedPaymentMethods: methods?.length,
        paymentMethodsList: getBiPaymentMethodsList(methods),
      }),
    );
  };

  return {
    reportPageLoadStarted,
    reportPageLoaded,
  };
};
