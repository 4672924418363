import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  listSavedPaymentMethods,
  markSavedPaymentMethodPrimary,
  deleteSavedPaymentMethod,
} from '@wix/ambassador-payments-saved-payment-methods-v1-saved-payment-method/http';
import type {
  ListSavedPaymentMethodsRequest,
  ListSavedPaymentMethodsResponse,
  MarkSavedPaymentMethodPrimaryRequest,
  DeleteSavedPaymentMethodRequest,
} from '@wix/ambassador-payments-saved-payment-methods-v1-saved-payment-method/types';

export const fetchSavedPaymentMethods = async (
  httpClient: IHttpClient,
  payload: ListSavedPaymentMethodsRequest = {},
): Promise<ListSavedPaymentMethodsResponse> => {
  const { data } = await httpClient.request(listSavedPaymentMethods(payload));

  return data;
};

export const markSavedPaymentMethodAsPrimary = async (
  httpClient: IHttpClient,
  payload: MarkSavedPaymentMethodPrimaryRequest,
): Promise<void> => {
  await httpClient.request(markSavedPaymentMethodPrimary(payload));
};

export const removeSavedPaymentMethod = async (
  httpClient: IHttpClient,
  payload: DeleteSavedPaymentMethodRequest,
): Promise<void> => {
  await httpClient.request(deleteSavedPaymentMethod(payload));
};
