import type { VisitorLogger } from '@wix/yoshi-flow-editor';
import type { AppStore } from '@infrastructure/redux/store.types';
import type {
  myWalletModalStartedLoadingParams,
  myWalletModalFinishedLoadingParams,
  myWalletModalClosedParams,
} from '@wix/bi-logger-payments-data/v2/types';
import {
  myWalletModalStartedLoading,
  myWalletModalFinishedLoading,
  myWalletModalClosed,
} from '@wix/bi-logger-payments-data/v2';
import { savedPaymentMethodsSelectors } from '@infrastructure/redux/savedPaymentMethod';
import { mapToBiPaymentMethodData } from '../BiLogger.helpers';

export const modalsLogger = (logger: VisitorLogger, store: AppStore) => {
  const modalLoadStartTime: Record<string, number | undefined> = {};

  const getBiPaymentMethodData = (savedPaymentMethodId?: string) => {
    if (savedPaymentMethodId) {
      const paymentMethod = savedPaymentMethodsSelectors.getMethodById(
        savedPaymentMethodId,
      )(store.getState());

      return mapToBiPaymentMethodData(paymentMethod);
    }
  };

  const reportModalLoadStarted = (
    params: myWalletModalStartedLoadingParams,
  ) => {
    const { modalType, savedPaymentMethodId } = params;

    if (modalType) {
      modalLoadStartTime[modalType] = Date.now();
    }

    logger.report(
      myWalletModalStartedLoading({
        ...params,
        ...getBiPaymentMethodData(savedPaymentMethodId),
      }),
    );
  };

  const reportModalLoaded = (params: myWalletModalFinishedLoadingParams) => {
    const { modalType = '', savedPaymentMethodId } = params;
    const startTime = modalLoadStartTime[modalType];

    logger.report(
      myWalletModalFinishedLoading({
        ...params,
        ...getBiPaymentMethodData(savedPaymentMethodId),
        duration: startTime && Date.now() - startTime,
      }),
    );

    if (modalType) {
      delete modalLoadStartTime[modalType];
    }
  };

  const reportModalClosed = (params: myWalletModalClosedParams) => {
    const { savedPaymentMethodId } = params;

    logger.report(
      myWalletModalClosed({
        ...params,
        ...getBiPaymentMethodData(savedPaymentMethodId),
      }),
    );
  };

  return {
    reportModalLoadStarted,
    reportModalLoaded,
    reportModalClosed,
  };
};
